import { Box, IconButton, Stack, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSpringCarousel } from 'react-spring-carousel';
import ArticleItem from '../../BlogPage/ArticleItem';
import arrowLeftIcon from './arrow-left.svg';
import arrowRightIcon from './arrow-right.svg';

export const SectionStoriesCarousel = ({ stories }) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const itemsPerPage = isMobile ? 1.1 : 3;

  const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
    withLoop: false,
    itemsPerSlide: itemsPerPage,
    items: stories.map(({ slug, meta }, index) => ({
      id: `story-${index}`,
      renderItem: (
        <Box height="100%" width="100%" mr={2}>
          <ArticleItem key={`${slug}-${intl.locale}`} slug={slug} meta={meta} fullWidth hideDescription />
        </Box>
      ),
    })),
  });

  return (
    <Stack
      direction="row"
      justifyContent="center"
      position="relative"
      sx={{
        mx: 'auto',
        maxWidth: 1096,
        overflow: 'hidden',
        '@media (max-width: 1439px)': {
          maxWidth: '100vw',
        },
      }}
    >
      {!isMobile && (
        <>
          <IconButton
            onClick={slideToPrevItem}
            sx={{
              position: 'absolute',
              left: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 32,
              height: 32,
              zIndex: 1,
            }}
          >
            <img src={arrowLeftIcon} alt="Previous" />
          </IconButton>
          <IconButton
            onClick={slideToNextItem}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 32,
              height: 32,
              zIndex: 1,
            }}
          >
            <img src={arrowRightIcon} alt="Next" />
          </IconButton>
        </>
      )}
      <Box
        sx={{
          position: 'relative',
          px: { xs: 2, md: 0 },
          ml: { xs: 0, md: 2 },
          width: theme => ({ xs: '100%', md: `calc(100% - ${theme.spacing(10)})` }),
          overflow: { xs: 'visible', md: 'hidden' },
          gap: 2,
        }}
      >
        {carouselFragment}
      </Box>
    </Stack>
  );
};
