import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Rating } from './Rating';

export const ReviewCard = ({ review }) => {
  const intl = useIntl();
  const comment = intl.locale === 'pl' ? review.commentPl : review.commentEn;

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '12px',
        px: 2,
        py: 3,
        height: '100%',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Rating value={review.rating} />
      <Typography
        variant="body1"
        sx={{
          mt: 1.5,
          mb: 'auto',
          color: '#495057',
          fontSize: 14,
        }}
      >
        {comment}
      </Typography>
      <Stack direction="column" alignItems="start" mt={2}>
        <Typography variant="body1" sx={{ fontSize: 14, color: '#212529' }}>
          {review.name}
        </Typography>
        <Typography variant="body2" sx={{ color: '#212529', fontSize: 14 }}>
          {review.verified
            ? intl.formatMessage({ id: 'landing_page.section_reviews.verified' })
            : intl.formatMessage({ id: 'landing_page.section_reviews.customer' })}
        </Typography>
      </Stack>
    </Box>
  );
};
