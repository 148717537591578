import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SectionWhyCarousel } from '../../containers/LandingPage/SectionWhy/SectionWhyCarousel';
import SectionContainer from '../SectionContainer/SectionContainer';

export const FeatureSection = ({ title, description, items, isLight }) => {
  const renderIcon = icon => {
    if (typeof icon === 'string' && icon.endsWith('.svg')) {
      return <Box component="img" src={icon} alt="" width={32} height={32} />;
    }
    return (
      <Typography fontSize={24} fontWeight={600} color={isLight ? 'white' : '#0D241A'}>
        {icon}
      </Typography>
    );
  };

  return (
    <SectionContainer bgColor={isLight ? '#E9EAE9' : '#0D241A'}>
      <Stack mb={{ xs: 2, md: 8 }}>
        <Typography color={isLight ? '#0D241A' : 'white'} fontSize={24} fontWeight={600} textAlign="center">
          {title}
        </Typography>
        {description && (
          <Typography
            color={isLight ? '#0D241A' : 'white'}
            fontSize={16}
            mt={2}
            textAlign="center"
            sx={{ opacity: 0.8 }}
          >
            {description}
          </Typography>
        )}
      </Stack>

      <Box display={{ xs: 'block', md: 'none' }} width="100%">
        <SectionWhyCarousel isLight={isLight} features={items} />
      </Box>

      <Stack
        display={{ xs: 'none', md: 'flex' }}
        direction="row"
        justifyContent="space-between"
        px={{ md: 8 }}
        gap={4}
        width="100%"
      >
        {items.map((item, index) => (
          <Stack key={index} alignItems="center" textAlign="center" width="220px" gap={2}>
            <Box
              bgcolor={isLight ? '#0D241A' : '#E9EAE9'}
              borderRadius="50%"
              p={2}
              mb={1}
              height={64}
              width={64}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {renderIcon(item.icon)}
            </Box>
            <Typography color={isLight ? '#0D241A' : 'white'} fontSize={24} fontWeight={600}>
              {item.title}
            </Typography>
            <Typography color={isLight ? '#0D241A' : 'white'} fontSize={16} sx={{ opacity: 0.8 }}>
              {item.description}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </SectionContainer>
  );
};

FeatureSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string.isRequired]),
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLight: PropTypes.bool,
};

FeatureSection.defaultProps = {
  description: null,
  isLight: false,
};
