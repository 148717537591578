import { Stack, Typography } from '@mui/material';
import starIcon from './star.svg';

export const Rating = ({ value, max = 5 }) => {
  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      {[...Array(max)].map((_, index) => (
        <img
          key={index}
          src={starIcon}
          alt="star"
          style={{
            width: 20,
            height: 20,
            opacity: index < value ? 1 : 0.3,
          }}
        />
      ))}
      <Typography variant="body1" ml={0.5} sx={{ color: '#212529', fontSize: 14 }}>
        {value}/5
      </Typography>
    </Stack>
  );
};
