import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Parts from '../../assets/icons/categories/medium/parts.png';
import SkisTop from '../../assets/icons/categories/medium/skis_top.png';
import { NamedLink, UniversalButton } from '../../components';
import { filters } from '../../config/marketplace-custom-config';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { AnimatedListItem } from './AnimatedListItem';

import { HorizontalList } from './HorizontalList';
import css from './SectionCategoriesGrid.module.css';

function CategoryCard(props) {
  const { name, categoryKey, icon, history, categoryType = 'bike', isMobile = false } = props;

  return (
    <NamedLink
      name="SearchPage"
      params={{ type: categoryType, category: categoryKey }}
      state={{ prevPath: history.location.pathname }}
      style={{ display: isMobile ? 'flex' : null }}
    >
      <AnimatedListItem height="100%" width={isMobile ? '40vw' : null}>
        <Stack gap={2} p={2} alignItems="center" height="100%">
          <Box
            component="img"
            src={icon}
            height="auto"
            width="100%"
            sx={{ objectFit: 'contain', objectPosition: 'center', aspectRatio: 1, maxHeight: 95 }}
          />
          <Typography fontSize={{ xs: 16, md: 18 }} fontWeight={400} textAlign="center" mt="auto">
            {name}
          </Typography>
        </Stack>
      </AnimatedListItem>
    </NamedLink>
  );
}

export const SectionCategoriesGrid = props => {
  const { countryCode } = props;
  const history = useHistory();
  const intl = useIntl();
  const categories = filters.find(filter => filter.id === 'category').config.options;

  const categoriesForDisplay = ['mountain', 'electric', 'gravel', 'road'];

  const categoryCards = categoriesForDisplay.map(key => categories.find(category => category.key === key));

  const categoryCardsWithPartsAndWinter = [
    ...categoryCards,
    {
      categoryType: 'parts',
      history,
      logo: Parts,
    },
    {
      categoryType: 'winter',
      history,
      logo: SkisTop,
    },
  ];

  const handleShopClick = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, { countryCode }));
  };

  return (
    <Stack bgcolor="#E9EAE9">
      <Stack
        direction={{ xs: 'column-reverse', md: 'row' }}
        mx="auto"
        overflow="hidden"
        py={{ xs: 2, md: 8 }}
        sx={{
          width: '100%',
          maxWidth: 1096,
          borderRadius: 4,
          px: 0,
          overflow: 'hidden',
          '@media (max-width: 1439px)': {
            maxWidth: '100vw',
            px: 2,
            borderRadius: 0,
          },
        }}
      >
        {/* Desktop */}
        <div className={`${css.grid} ${css.mobileHidden}`}>
          {categoryCardsWithPartsAndWinter.map(category => (
            <CategoryCard
              name={intl.formatMessage({ id: `categories.${category.key || category.categoryType}` })}
              key={category.key || category.categoryType}
              categoryKey={category.key}
              categoryType={category.categoryType}
              icon={category.bigLogo ?? category.logo}
              history={history}
            />
          ))}
        </div>

        <HorizontalList className={`${css.desktopHidden} ${css.horizontalList}`}>
          {categoryCardsWithPartsAndWinter.map(category => (
            <CategoryCard
              name={intl.formatMessage({ id: `categories.${category.key || category.categoryType}` })}
              key={category.key || category.categoryType}
              categoryKey={category.key}
              categoryType={category.categoryType}
              isMobile
              icon={category.bigLogo ?? category.logo}
              history={history}
            />
          ))}
        </HorizontalList>
        <Stack
          width="50%"
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          px={{ xs: 0, md: 6 }}
          py={3}
          gap={2}
          sx={{
            width: { xs: '100%', md: '50%' },
            '& button': {
              width: { xs: '100%', md: 280 },
            },
          }}
        >
          <Typography color="#000000" className={css.grid__title}>
            {intl.formatMessage({ id: 'landing_page.grid.gear_fuels_passion' })}
            <UniversalButton
              onClick={handleShopClick}
              className={css.desktopHidden}
              width="auto"
              type="secondary"
              style={{
                width: 'auto',
                padding: '8px 16px',
                lineHeight: '1',
                height: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              {intl.formatMessage({ id: 'landing_page.grid.shop_all' })}
            </UniversalButton>
          </Typography>
          <Typography color="#495057" className={css.grid__description}>
            {intl.formatMessage({ id: 'landing_page.grid.gear_fuels_passion_description' })}
          </Typography>
          <UniversalButton type="primary" onClick={handleShopClick} mt={2} className={css.mobileHidden}>
            {intl.formatMessage({ id: 'landing_page.grid.shop_all' })}
          </UniversalButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
