import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { SectionContainer, UniversalButton } from '../../../components';
import ArticleItem from '../../BlogPage/ArticleItem';
import { articles } from '../../BlogPage/articles.lock';
import css from '../LandingPage.module.css';
import { SectionStoriesCarousel } from './SectionStoriesCarousel';

export const SectionStories = () => {
  const intl = useIntl();
  const filteredArticles = articles
    .filter(article => article.language === intl.locale)
    .sort((a, b) => new Date(b.meta.date) - new Date(a.meta.date))
    .slice(0, 3);

  if(filteredArticles.length === 0) return null;

  return (
    <Stack gap={3} mb={10}>
      <SectionContainer sx={{ pb: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h2" fontSize={{ xs: 20, md: 24 }} fontWeight={600}>
            {intl.formatMessage({ id: 'landing_page.stories.title' })}
          </Typography>
          <Box sx={{ button: { px: 2 } }}>
            <UniversalButton type="secondary" className={css.landingSectionButton}>
              {intl.formatMessage({ id: 'landing_page.view_more' })}
            </UniversalButton>
          </Box>
        </Stack>
        <Box display={{ xs: 'none', md: 'block' }} mt={3}>
          <Stack direction="row" gap={2}>
            {filteredArticles.map(({ slug, meta }) => (
              <ArticleItem key={`${slug}-${intl.locale}`} slug={slug} meta={meta} fullWidth hideDescription />
            ))}
          </Stack>
        </Box>
      </SectionContainer>
      <Box display={{ xs: 'block', md: 'none' }}>
        <SectionStoriesCarousel stories={filteredArticles} />
      </Box>
    </Stack>
  );
};
