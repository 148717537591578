import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSpringCarousel } from 'react-spring-carousel';
import { CarouselBubbles } from './CarouselBubbles';

export const SectionWhyCarousel = ({ features, isLight = false }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderIcon = icon => {
    if (typeof icon === 'string' && icon.endsWith('.svg')) {
      return <Box component="img" src={icon} alt="" width={32} height={32} />;
    }
    return (
      <Typography fontSize={24} fontWeight={600} color={isLight ? 'white' : '#0D241A'}>
        {icon}
      </Typography>
    );
  };

  const { carouselFragment, slideToNextItem, slideToItem, getCurrentActiveItem } = useSpringCarousel({
    withLoop: true,
    items: features.map((feature, index) => ({
      id: `feature-${index}`,
      renderItem: (
        <Stack alignItems="center" textAlign="center" width="100%" gap={2} p={2} mb={3}>
          <Box
            bgcolor={isLight ? '#0D241A' : '#E9EAE9'}
            borderRadius="50%"
            p={2}
            mb={1}
            height={64}
            width={64}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renderIcon(feature.icon)}
          </Box>
          <Typography color={isLight ? '#0D241A' : 'white'} fontSize={24} fontWeight={600}>
            {feature.title}
          </Typography>
          <Typography color={isLight ? '#0D241A' : 'white'} fontSize={16} sx={{ opacity: 0.8 }}>
            {feature.description}
          </Typography>
        </Stack>
      ),
    })),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      slideToNextItem();
      setActiveIndex(getCurrentActiveItem().index);
    }, 5000);

    return () => clearInterval(interval);
  }, [slideToNextItem]);

  return (
    <Box width="100%" position="relative">
      {carouselFragment}
      <CarouselBubbles dark={isLight} count={features.length} activeIndex={activeIndex} onClick={handleSlideToItem} />
    </Box>
  );

  function handleSlideToItem(index) {
    slideToItem(index);
    setActiveIndex(index);
  }
};
