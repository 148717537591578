import { Box, Link as MuiLink, Skeleton, Typography } from '@mui/material';
import Clamp from 'clamp-js';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const ArticleItem = ({ slug, meta, fullWidth = false, hideDescription = false }) => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const isSSR = typeof window === 'undefined';
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true, rootMargin: '100px' });

  useEffect(() => {
    if (titleRef.current) {
      Clamp(titleRef.current, { clamp: 2 });
    }
    if (descriptionRef.current) {
      Clamp(descriptionRef.current, { clamp: 3 });
    }
  }, []);

  const combinedRef = node => inViewRef(node);
  const handleImageLoad = () => setImageLoaded(true);

  return (
    <MuiLink
      href={`/blog/${slug}`}
      variant="h6"
      underline="hover"
      color="primary"
      sx={{
        position: 'relative',
        width: theme =>
          fullWidth
            ? '100%'
            : {
                xs: '100%',
                sm: `calc(50% - ${theme.spacing(2)})`,
                md: `calc(33.33% - ${theme.spacing(3)})`,
              },
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        textDecoration: 'none !important',
        '&:hover': {
          transform: 'scale(1.01)',
        },
      }}
    >
      <Box
        ref={combinedRef}
        sx={{
          position: 'relative',
          width: '100%',
          height: { xs: 180, md: 230 },
          borderRadius: '12px',
          overflow: 'hidden',
          mb: 1,
        }}
      >
        {!imageLoaded && <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />}
        {(isSSR || inView) && (
          <Box
            component="img"
            src={meta.bannerImage}
            alt={meta.title}
            onLoad={handleImageLoad}
            sx={{
              display: imageLoaded ? 'block' : 'none',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </Box>
      <Typography
        component={hideDescription ? 'h3' : 'h2'}
        color="textPrimary"
        fontSize={hideDescription ? 16 : { xs: 20, md: 24 }}
        fontWeight={600}
        ref={titleRef}
      >
        {meta.title}
      </Typography>
      {!hideDescription && (
        <Typography variant="body1" color="textPrimary" fontSize={16} ref={descriptionRef}>
          {meta.description}
        </Typography>
      )}
    </MuiLink>
  );
};

export default ArticleItem;
