import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  UniversalButton,
} from '../../components';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import thumbnailImg from '../../assets/gearroThumbnail.png';

import { getDefaultCountry, getDefaultCurrency } from '../../api/locale';
import { SectionReviews } from '../../components/SectionReviews/SectionReviews';
import { useRootContext } from '../../contexts/RootContext';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { fetchRecentListings } from './LandingPage.duck';
import css from './LandingPage.module.css';
import { SectionCategoriesGrid } from './SectionCategoriesGrid';
import { SectionHero } from './SectionHero/SectionHero';
import { SectionPopularBrands } from './SectionPopularBrands/SectionPopularBrands';
import SectionRecentItems from './SectionRecentItems/SectionRecentItems';
import { SectionStories } from './SectionStories/SectionStories';
import { SectionWhy } from './SectionWhy/SectionWhy';

export function LandingPageComponent(props) {
  const {
    history,
    intl,
    scrollingDisabled,
    recentListings,
    onLocaleChange,
    currentLocale,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId,
    fetchRecentBikeListings,
  } = props;

  const { user } = useRootContext();
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'Landing_page.schema_title' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'landing_page.schema_description' });
  const host = typeof window !== 'undefined' && window.location && window.location.host;
  const schemaImage = `${host}${thumbnailImg}`;
  const countryCode = user?.countryCode || getDefaultCountry(host);

  useEffect(() => {
    fetchRecentBikeListings(countryCode);
  }, []);

  const handleViewMore = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), { type: 'bike' }, { countryCode }));
  };

  const renderRecentListings = (listings, label) => (
    <div className={css.mostRecentItemsContainer}>
      <div className={css.mostRecentItemsHeader}>
        <h2 className={css.mostRecentItemsContainerTitle}>{label}</h2>
        <UniversalButton type="secondary" onClick={handleViewMore}>
          {intl.formatMessage({ id: 'landing_page.view_more' })}
        </UniversalButton>
      </div>
      <SectionRecentItems
        recentListings={listings}
        intl={intl}
        likedListingsIds={likedListingsIds}
        addUserFavorite={addUserFavorite}
        removeUserFavorite={removeUserFavorite}
        currentUserId={currentUserId}
        currentUserCurrency={getDefaultCurrency(user?.countryCode)}
        isSmallWidth
      />
    </div>
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: thumbnailImg, width: 842, height: 595 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
      displayBanner
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero countryCode={countryCode} />

          {/* Recent listings */}
          {recentListings &&
            renderRecentListings(
              recentListings.slice(0, 5),
              intl.formatMessage({ id: 'landing_page.most_recent_label' })
            )}

          {/* Sell bike ad */}
          <Box mt={9}>
            <SectionCategoriesGrid countryCode={countryCode} />
          </Box>

          {/* Popular brands */}

          {/* Categories */}
          {/* <Box mt={6} mb={10}> */}
          {/* <SectionCategories intl={intl} /> */}
          {/* </Box> */}

          {/* Recommended listings */}

          <Box mt={3}>
            {recentListings &&
              renderRecentListings(
                recentListings.slice(5, 10),
                intl.formatMessage({ id: 'landing_page.most_popular_label' })
              )}
          </Box>

          <Box mt={9}>
            <SectionPopularBrands intl={intl} history={history} />
          </Box>

          {/* {renderBuyButton('bike', intl.formatMessage({ id: 'landing_page.buy_bike_button' }))}
          {recentPartListings?.length > 0 &&
            renderRecentListings(recentPartListings, intl.formatMessage({ id: 'landing_page.most_recent_part_label' }))}
          {recentPartListings?.length > 0 &&
            renderBuyButton('parts', intl.formatMessage({ id: 'landing_page.buy_parts_button' }))} */}

          {/* <Box mt={9}>
            <SectionCreateListing />
          </Box> */}

          <Box mt={6}>
            <SectionReviews />
          </Box>

          <Box mt={0}>
            <SectionWhy />
          </Box>

          <Box mt={2}>
            <SectionStories />
          </Box>

          {/* How it works */}
          {/* <Box mt={0}>
            <SectionInfo intl={intl} />
          </Box> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer className={css.footer} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  history: object.isRequired,
  location: object.isRequired,

  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { recentListingIds, recentPartListingsIds } = state.LandingPage;

  const { currentUserFavoriteListingsIds, currentUser } = state.user;

  const recentListings = getListingsById(state, recentListingIds);
  const recentPartListings = getListingsById(state, recentPartListingsIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    recentListings,
    recentPartListings,
    likedListingsIds: currentUserFavoriteListingsIds,
    currentUserId: currentUser?.id?.uuid,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRecentBikeListings: countryCode => dispatch(fetchRecentListings(countryCode)),
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: listingId => dispatch(removeCurrentUserFavoriteListing(listingId)),
});

const LandingPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
