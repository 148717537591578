import { Box } from '@mui/material';
import Slide1New from '../../../assets/carousel/slide-1-new.webp';
import Slide2New from '../../../assets/carousel/slide-2-new.webp';
import Slide1SmallNew from '../../../assets/carousel/slide-1-new-small.webp';
import Slide2SmallNew from '../../../assets/carousel/slide-2-new-small.webp';
import Slide3SmallNew from '../../../assets/carousel/slide-3-new-small.webp';
import Slide3New from '../../../assets/carousel/slide-3-new.webp';
import { injectIntl } from '../../../util/reactIntl';
import { HeroCarousel } from './carousel/HeroCarousel';

const generateLtItems = countryCode => [
  {
    id: 'slide_2',
    src: Slide1New,
    srcSmall: Slide1SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'SearchPage',
    seachParams: { countryCode },
  },
  {
    id: 'slide_winter',
    src: Slide2New,
    srcSmall: Slide2SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'NewListingPage',
  },
  {
    id: 'slide_1',
    src: Slide3New,
    srcSmall: Slide3SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'NewListingPage',
    isMainSlide: true,
  },
];

const generatePlItems = countryCode => [
  {
    id: 'slide_1',
    src: Slide3New,
    srcSmall: Slide3SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'SearchPage',
    seachParams: { countryCode },
    isMainSlide: true,
  },
  {
    id: 'slide_2',
    src: Slide1New,
    srcSmall: Slide1SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'NewListingPage',
  },
  {
    id: 'slide_winter',
    src: Slide2New,
    srcSmall: Slide2SmallNew,
    buttonType: 'contrast',
    color: 'primary.contrastText',
    to: 'NewListingPage',
  },
];

const SectionHeroComponent = props => {
  const { intl, countryCode } = props;

  const items = intl.locale === 'pl' ? generatePlItems(countryCode) : generateLtItems(countryCode);
  return (
    <Box
      width="100%"
      height={{ xs: 375, sm: 400 }}
      mt={{ xs: 0, sm: 4 }}
      borderRadius={{ xs: 0, sm: '12px' }}
      overflow="hidden"
      sx={{
        maxWidth: 1320,
        mx: 'auto',
        '@media (max-width: 1439px)': { maxWidth: theme => `calc(100vw - ${theme.spacing(6)})`, mx: 3 },
        '@media (max-width: 767px)': { maxWidth: theme => `calc(100vw - ${theme.spacing(4)})`, mx: 2 },
        '@media (max-width: 600px)': { maxWidth: '100vw', mx: 0 },
        '@media (min-width: 320px) and (max-width: 600px)': { height: 500 },
      }}
    >
      <HeroCarousel items={items} />
    </Box>
  );
};

// inject intl

export const SectionHero = injectIntl(SectionHeroComponent);
