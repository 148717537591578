import { Box, Stack } from '@mui/material';
import CanyonLogo from '../../../assets/brands/canyon-2.svg';
import GiantLogo from '../../../assets/brands/giant-2.svg';
import ScottLogo from '../../../assets/brands/scott-2.svg';
import SpecializedLogo from '../../../assets/brands/specialized-2.svg';
import TrekLogo from '../../../assets/brands/trek-2.svg';
import WilierLogo from '../../../assets/brands/wilier-2.svg';
import { NamedLink } from '../../../components';
import { AnimatedListItem } from '../AnimatedListItem';
import { HorizontalList } from '../HorizontalList';

export const SectionPopularBrands = ({ intl, history }) => (
  <HorizontalList title={intl.formatMessage({ id: 'landing_page.popular_brands' })}>
    {[
      { key: 'scott', src: ScottLogo },
      { key: 'giant', src: GiantLogo },
      { key: 'trek', src: TrekLogo },
      { key: 'canyon', src: CanyonLogo },
      { key: 'specialized', src: SpecializedLogo },
      { key: 'wilier', src: WilierLogo },
    ].map(({ key, src }) => (
      <NamedLink
        key={key}
        name="SearchPage"
        params={{ type: 'bike' }}
        to={{ search: `?pub_brand=${key}` }}
        state={{ prevPath: history.location.pathname }}
        style={{ flex: 1 }}
      >
        <AnimatedListItem>
          <Stack
            width={{ xs: 120, md: '100%' }}
            height={{ xs: 82, md: 92 }}
            alignItems="center"
            justifyContent="center"
          >
            <Box component="img" src={src} alt={key} px={2} height="auto" width={{ xs: 136, md: '100%' }} />
          </Stack>
        </AnimatedListItem>
      </NamedLink>
    ))}
  </HorizontalList>
);
