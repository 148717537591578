import { Box, Stack } from '@mui/material';

export const CarouselBubbles = ({ count, activeIndex, onClick, dark = false }) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      {Array.from({ length: count }).map((_, index) => (
        <Box
          key={index}
          onClick={() => onClick(index)}
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            border: `1px solid ${dark ? '#0D241A' : 'white'}`,
            backgroundColor: activeIndex === index ? (dark ? '#0D241A' : 'white') : 'transparent',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
        />
      ))}
    </Stack>
  );
};
