import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import reviews from './reviews.json';
import { SectionReviewsCarousel } from './SectionReviewsCarousel';

export const SectionReviews = () => {
  const intl = useIntl();

  return (
    <Box sx={{ backgroundColor: '#E9EAE9', py: { xs: 5, md: 9 } }} width="100%">
      <Stack alignItems="center" gap={2} mb={{ xs: 3, md: 5 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            textAlign: 'center',
            color: '#07020D',
            fontSize: { xs: 18, md: 24 },
            fontWeight: 600,
          }}
        >
          {intl.formatMessage({ id: 'landing_page.section_reviews.title' })}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            color: '#495057',
            fontSize: { xs: 16, md: 18 },
            fontWeight: 400,
          }}
        >
          {intl.formatMessage({ id: 'landing_page.section_reviews.subtitle' })}
        </Typography>
      </Stack>

      <SectionReviewsCarousel reviews={reviews} />
    </Box>
  );
};
