import { useIntl } from 'react-intl';
import VerifiedUsersIcon from '../../../assets/icons/why-gearro/chat.svg';
import CreditCardIcon from '../../../assets/icons/why-gearro/credit-card.svg';
import ShieldIcon from '../../../assets/icons/why-gearro/shield.svg';
import FreeShippingIcon from '../../../assets/icons/why-gearro/shipping.svg';
import { FeatureSection } from '../../../components/FeatureSection/FeatureSection';

export const SectionWhy = () => {
  const intl = useIntl();

  const features = [
    {
      icon: CreditCardIcon,
      title: intl.formatMessage({ id: 'landing_page.why_gearro.title_1' }),
      description: intl.formatMessage({ id: 'landing_page.why_gearro.description_1' }),
    },
    {
      icon: FreeShippingIcon,
      title: intl.formatMessage({ id: 'landing_page.why_gearro.title_2' }),
      description: intl.formatMessage({ id: 'landing_page.why_gearro.description_2' }),
    },
    {
      icon: VerifiedUsersIcon,
      title: intl.formatMessage({ id: 'landing_page.why_gearro.title_3' }),
      description: intl.formatMessage({ id: 'landing_page.why_gearro.description_3' }),
    },
    {
      icon: ShieldIcon,
      title: intl.formatMessage({ id: 'landing_page.why_gearro.title_4' }),
      description: intl.formatMessage({ id: 'landing_page.why_gearro.description_4' }),
    },
  ];

  return <FeatureSection title={intl.formatMessage({ id: 'landing_page.why_gearro.why' })} items={features} />;
};
